import React, { useState, useRef, useEffect, useImperativeHandle } from "react";

const Rekaman = ({ data, destroy }) => {
  return (
    <div className="text-center">
      <div className="card shadow">
        <div className="card-body">
          <h5 className="card-title">Segera Hadir</h5>
        </div>
      </div>
    </div>
  );
};
export default Rekaman;
