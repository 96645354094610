import React,{useState, useEffect} from "react";

import { Link } from "react-router-dom";
/// Scroll
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import LogoutPage from './Logout';

/// Image
import profile from "../../../images/user.jpg";
import avatar from "../../../images/avatar/1.jpg";

const Header = ({ onNote }) => {
  const [searchBut, setSearchBut] = useState(false);	

  //For header fixed 
  	const [headerFix, setheaderFix] = useState(false);
	const [userData,setUserData] = useState([]);
	useEffect(() => {
		setUserData(JSON.parse(localStorage.getItem('userDetails')))
		console.log(localStorage.getItem('userDetails'))
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []); 
  
  return ( 
    <div className={`header ${ headerFix ? "sticky" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
				
            </div>
			<ul className="navbar-nav header-right">

			    <li className="nav-item ">
					<Dropdown className="dropdown header-profile2">
						<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">							
							<div className="header-info2 d-flex align-items-center">
								<div className="d-flex align-items-center sidebar-info">
									<div>
										<h4 className="mb-0">{userData.displayName}</h4>
										<span className="d-block text-end">{userData.username}</span>
									</div>
								</div>
								<img src={profile}  alt="" />
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
							<LogoutPage />
						</Dropdown.Menu>
					</Dropdown>	
			    </li>  	
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
