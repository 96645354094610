import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPostAction, createPostFileAction, updatePostAction } from "../../../store/actions/PostActions";
import { useDispatch } from "react-redux";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();
  const [type, setType] = useState('option');
  const [plat, setPlat] = useState('');
  const [editId, setEditId] = useState('');
  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
    openEdit(item) {
      setModalBox(true);
      setType(item.type)
      setPlat(item.plat)
      setEditId(item.id)
    }
  }));
  const submitActivity = async (e) => {
    e.preventDefault();
    const data = {
      type: type,
      plat: plat,
    }
    let response = null;
    if (editId) {
      response = await dispatch(updatePostAction('/kendaraan/update', data, editId))
    } else {
      response = await dispatch(createPostAction('/kendaraan/store', data))
    }
    if (response) {
      if (!editId) {
        props.handlePrint(response.data.plat)
      }
      props.fetchActivities()
      setModalBox(false);

      resetInput();
    }
  }
  const resetInput = () => {
    setType('')
    setPlat('')
    setEditId('');
  }

  return (
    <>
      <Modal
        show={modalBox}
        onHide={setModalBox}
        className="fade bd-example-modal-lg"
        size="lg"
      >
        <>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Isi Data Kendaraan</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => { setModalBox(false); resetInput() }}
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  submitActivity(e)
                }}
              >
                <div className="row">
                  <div className="d-flex flex-column gap-3 mb-3 ">
                    <div className="form-group">
                      <label>Plat Kendaraan</label>
                      <input className="form-control" placeholder="BL 1234 ABC" value={plat} onChange={(e) => setPlat(e.target.value)} />
                    </div>
                    <div className="form-group">
                      <label>Jenis Kendaraan</label>
                      <select value={type} className="form-control" onChange={(e) => setType(e.target.value)}>
                        <option value="option" disabled>
                          Choose...
                        </option>
                        <option value="Motor">Motor</option>
                        <option value="Mobil">Mobil</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Buat Karcis
                </button>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
});
export default BasicModal;
