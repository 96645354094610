import React, { useState, useReducer, useEffect, useRef } from "react";
import { Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import BasicModal from "./BasicModal";
import { useDispatch } from "react-redux";
import { deletePostAction, getPostsAction, updatePostAction } from "../../../store/actions/PostActions";
import Kendaraan from "../../../images/kendaraan.png";
import moment from "moment";
import Swal from "sweetalert2";
import Barcode from "react-barcode";
export const Activities = () => {
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const childRef = useRef();
  const [plat, setPlat] = useState('');
  const [inputPlat, setInputPlat] = useState('');
  const itemsPerPage = 10;
  const totalPages = Math.ceil(activities.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentActivities = activities.slice(startIndex, startIndex + itemsPerPage);
  const [status, setStatus] = useState(0)
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    fetchActivities()
  }, [currentPage, status])
  const destroyActivity = async (e, deleteId, code) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Keluarkan Kendaraan",
      html: `
    <div class="form-group text-start fw-bold">
    Kode Tiket
    <input class="form-control mt-2 bg-gray" disabled value="${code}"/>
    <div/>
  `,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Keluarkan"
    });
    if (result.isConfirmed) {
      const response = await dispatch(updatePostAction('/kendaraan/update', { keluar: true }, deleteId))
      if (response) {
        fetchActivities()
      }
    }

  }
  const fetchActivities = async (plat = null) => {
    try {
      const posts = await dispatch(getPostsAction('/kendaraan/index', {
        params: {
          plat: plat,
          keluar: status,
          pageSize: 10,
          pageNumber: currentPage,
        },
      }))
      if (posts) {
        setActivities(posts)
      }
    } catch (error) {
    }
  }
  const printAreaRef = useRef(null);
  const handlePrint = (code) => {
    setPlat(code);

    setTimeout(() => {
      const printContent = printAreaRef.current;
      const printWindow = window.open('', '', 'width=600,height=600');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Barcode</title>
          </head>
          <body>${printContent.innerHTML}</body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }, 1000);
  };
  let timeoutId
  const loadUser = (inputValue) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      if (inputValue.trim()) {
        fetchActivities(inputValue);
      }
      if (!inputValue) {
        fetchActivities()
      }
    }, 500);
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-3 ">
          <img src={Kendaraan} />
          <div>
            <h3>Data Kendaraan</h3>
            <span className="fs-5">{activities.length} Kendaraan</span>
          </div>

        </div>
        <div className="d-flex align-items-center gap-3">
          {/* <div className="align-items-end d-flex gap-3 me-3">
            <button className={`btn ${status == 0 ? 'btn-secondary' : 'btn-outlined-secondary'} d-flex w-auto align-self-start py-1`} onClick={() => setStatus(0)}>Masuk</button>
            <button className={`btn ${status == 1 ? 'btn-secondary' : 'btn-outline-secondary'} d-flex w-auto align-self-start py-1`} onClick={() => setStatus(1)}>Keluar</button>
          </div> */}
          <div className="input-group search-area ">
            <input
              type="text"
              className={`form-control active`}
              placeholder="Cari plat kendaraan"
              onChange={(e) => loadUser(e.target.value)}
            />
            <span className="input-group-text">
              <Link to={"#"}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                    fill="#01A3FF"
                  />
                </svg>
              </Link>
            </span>
          </div>
          <button
            className="btn btn-secondary w-100"
            data-toggle="modal"
            onClick={() => childRef.current.openModal()}
          >
            + Tambah Kendaraan
          </button>

        </div>

      </div>
      <div ref={printAreaRef} className="d-none">
        <Barcode value={plat} />
      </div>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>No</th>
            <th>Jenis Kendaraan</th>
            <th>Plat</th>
            <th>Waktu</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((item, key) => (
            <tr key={key}>
              <th>{key + 1}</th>
              <td className="align-top">
                {item.type}
              </td>
              <td className="align-top">
                {item.plat}
              </td>
              <td className="align-top">
                {moment(item.time).format('DD MMMM hh:mm')}
              </td>
              <td className="align-top">
                <div className="d-flex justify-content-center gap-2">
                  {
                    !status && <button title="Edit" className="btn btn-primary shadow btn sharp" onClick={() => childRef.current.openEdit(item)}>
                      <i className="fas fa-pencil-alt color-muted"></i>
                    </button>
                  }

                  <button title="Edit" className="btn btn-secondary shadow btn sharp" onClick={() => handlePrint(item.plat)}>
                    <i class="fa-solid fa-print"></i>
                  </button>
                  {
                    !status &&
                    <button
                      onClick={(e) => destroyActivity(e, item.id, item.plat)}
                      title="delete"
                      className="btn btn-danger shadow btn py-1"
                    >
                      Keluarkan
                    </button>
                  }
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="table-pagenation">
        <p className="ms-0">
          Showing <span>{startIndex + 1}-{Math.min(startIndex + currentActivities.length, activities.length)}</span>
          from <span>{activities.length}</span> data
        </p>

        <nav>
          <ul className="pagination pagination-gutter pagination-primary no-bg">
            <li className={`page-item page-indicator ${currentPage === 1 && "disabled"}`}>
              <Link
                to={"#"}
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <i className="fa-solid fa-angle-left"></i>
              </Link>
            </li>

            {/* Generate pagination numbers */}
            {[...Array(totalPages)].map((_, index) => (
              <li className={`page-item ${currentPage === index + 1 ? "active" : ""}`} key={index}>
                <Link
                  to={"#"}
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Link>
              </li>
            ))}

            <li className={`page-item page-indicator me-0 ${currentPage === totalPages && "disabled"}`}>
              <Link
                to={"#"}
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <i className="fa-solid fa-angle-right"></i>
              </Link>
            </li>
          </ul>
        </nav>

      </div>
      <BasicModal fetchActivities={fetchActivities} ref={childRef} handlePrint={handlePrint} />
    </>
  );
};
export default Activities;
